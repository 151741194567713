var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"neu-glow"},[_c('v-row',{staticClass:"mx-0 pa-2",attrs:{"align":"center"}},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"neu-glow-inset",attrs:{"headers":_vm.headers,"items":_vm.movements,"items-per-page":5,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[(item.user && item.user.name)?_c('td',[_vm._v(" "+_vm._s(item.user.name + ' ' + item.user.lastname)+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(item.date))]),_c('td',{staticClass:"d-flex",staticStyle:{"justify-content":"center","align-items":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"grey"},on:{"click":function($event){return _vm.handleSelected(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify-scan")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver movimiento")])])],1)])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center"}),_vm._v(" No hay movimientos realizados ")]},proxy:true},{key:"no-results",fn:function(){return [_c('div',{staticClass:"text-center"}),_vm._v(" No hay resultados para su búsqueda ")]},proxy:true}])})],1),_c('modal',{attrs:{"name":'modal' + _vm.type.toString(),"adaptive":true}},[_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"0"}},[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("toTypeText")(_vm.type))+" de "+_vm._s(_vm.position.name))]),(Object.keys(_vm.movement).length !== 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Usuario:")]),_vm._v(" "+_vm._s(_vm.movement.user.name + ' ' + _vm.movement.user.lastname)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Tipo de movimiento:")]),_vm._v(" "+_vm._s(_vm._f("toTypeText")(_vm.movement.type))+" ")]),(_vm.movement.movement_reason)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Tipo de movimiento:")]),_vm._v(" "+_vm._s(_vm.movement.movement_reason.description)+" ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Observación:")]),_vm._v(" "+_vm._s(_vm.movement.observation)+" ")])],1):_vm._e()],1),_c('v-col',{staticStyle:{"margin-bottom":"20px","height":"10%"},attrs:{"cols":"12"}},[_c('div',{staticClass:"row"},[_c('v-btn',{staticStyle:{"margin":"auto","margin-top":"10px"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.$modal.hide('modal' + _vm.type.toString())}}},[_vm._v(" Cerrar ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }