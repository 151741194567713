<template>
  <div class="vuse-content-wrapper">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <help class="help--breadcrumb" :helpId="42" />
    </v-row>
    <v-card color="with-radius">
      <v-row class="mx-0">
        <v-col cols="12" md="4">
          <v-card class="neu-glow-inset mx-0" style="height: 100%">
            <v-toolbar dense flat color="transparent">
              <v-btn icon color="blue"> <v-icon>public</v-icon> </v-btn
              >{{ position.name }}
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <div class="text-body-2" style="text-align: justify">
                Misión: {{ position.mission }}
              </div>
            </v-card-text>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Puestos autorizados: {{ position.vacancies }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Puestos asignados:
                  {{ position.users ? position.users.length : 0 }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <position-processes :position="position" />
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="align-self-center" align="start">
          <position-users :position="position" @reloadPosition="getPosition()" />
        </v-col>
      </v-row>
      <!-- <v-row>
          <v-col cols="4">
            <metric-list title="Educacion" />
          </v-col>
          <v-col cols="4">
            <metric-list title="Experiencia" />
          </v-col>
          <v-col cols="4">
            <metric-list title="Competencias" />
          </v-col>
        </v-row> -->
      <v-row class="mx-0">
        <v-col cols="12" md="6">
          <position-movements
            title="Incorporaciones"
            :type="1"
            :position="position"
            style="height: 100%"
          />
        </v-col>
        <v-col cols="12" md="6">
          <position-movements
            title="Transferencias"
            :type="2"
            :position="position"
            style="height: 100%"
          />
        </v-col>
        <v-col cols="12" md="6">
          <position-movements
            title="Ascensos"
            :type="3"
            :position="position"
            style="height: 100%"
          />
        </v-col>
        <v-col cols="12" md="6">
          <position-movements
            title="Desincorporaciones"
            :type="4"
            :position="position"
            style="height: 100%"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
  import PositionUsers from '@/components/Listing/HumanTalent/PositionUsers'
  import PositionProcesses from '@/components/Listing/HumanTalent/PositionProcesses'
  import PositionMovements from '@/components/Listing/HumanTalent/PositionMovements'

  export default {
    name: 'PositionProfile',
    components: {
      PositionUsers,
      PositionProcesses,
      PositionMovements,
      // MetricList,
    },
    data() {
      return {
        tab: 0,
        position: {},
        items: ['Actividad', 'Examenes', 'Comentarios', 'Lecciones'],
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Perfiles de Cargos',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/Positions',
            href: '/administrative/human-talent/positions',
          },
          {
            text: 'Perfil del cargo',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.getPosition()
    },
    methods: {
      getPosition() {
        this.$axios.get('positions/' + this.$route.params.positionId).then((response) => {
          this.position = response.data
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-breadcrumbs {
    padding: 5px 10px !important;
  }
</style>
