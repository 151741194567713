<template>
  <v-card class="neu-glow">
    <v-row class="mx-0 pa-2" align="center">
      <!-- title and subtitle -->
      <div class="text-h6">Manuales</div>
      <!-- <help :helpId="" style="'margin: auto 10px'" /> -->
      <v-spacer />
      <!-- Stats -->
      <div class="d-flex flex-column align-end">
        <v-btn
          icon
          class="neu-glow"
          @click="$router.push({ name: 'administrative/human-talent/Process' })"
        >
          <v-icon color="secondary">add</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-card-text class="pa-0" style="max-height: 400px; overflow-y: auto">
      <v-data-table
        :headers="headers"
        :items="processes"
        :items-per-page="5"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        class="neu-glow-inset"
    /></v-card-text>
  </v-card>
</template>

<script>
  export default {
    components: {},
    props: {
      position: Object,
    },
    data() {
      return {
        processes: [],
        loading: false,
        headers: [
          {
            text: 'Nombre',
            align: 'left',
            value: 'name',
          },
          { text: 'Acciones', value: 'action', align: ' d-none', sortable: false },
        ],
        page: 1,
        pages: 1,
        totalItems: 0,
        itemsPerPage: 10,
        loadingText: 'Cargando procesos',
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
      }
    },
    watch: {
      position(newValue) {
        this.getProcesses()
      },
    },
    methods: {
      async getProcesses() {
        this.loading = true
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        this.$axios
          .get(
            'processes?position_id=' +
              this.$route.params.positionId +
              '&page=' +
              this.page +
              orderString
          )
          .then((response) => {
            this.processes = response.data.data
            if (this.processes.length < 1) {
              this.loadingText = 'No hay procesos asignados'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loading = false
          })
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getProcesses()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
