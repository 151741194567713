<template>
  <v-card class="neu-glow">
    <v-row class="mx-0 pa-2" align="center">
      <!-- title and subtitle -->
      <div class="text-h6">Personal</div>
      <!-- <help :helpId="" style="'margin: auto 10px'" /> -->
      <!-- Stats -->
      <!-- <div class="d-flex flex-column align-end">
        <v-btn icon class="neu-glow">
          <v-icon color="secondary">add</v-icon>
        </v-btn>
      </div> -->
    </v-row>
    <v-card-text class="pa-0">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="users"
        :items-per-page="5"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        class="neu-glow-inset"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="d-flex" style="justify-content: center; align-items: center">
              <v-avatar size="45px">
                <img
                  v-if="item.avatar && item.avatar.route"
                  :src="item.avatar.route"
                  :alt="item.name"
                />
              </v-avatar>
            </td>
            <td>{{ item.fullName }}</td>
            <!-- <td class="text-xs-left">{{ item.deadline }}</td> -->
            <!-- <td class="text-xs-left">
              <v-progress-linear :value="item.progress" height="5" :color="item.color" />
              {{ item.progress }}%
            </td> -->
            <td class="text-xs-right">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="grey"
                    @click="getUser(item, 'edit')"
                  >
                    <v-icon>mdi-account-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Editar usuario</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="grey"
                    @click="getUser(item, 'roles')"
                  >
                    <v-icon>mdi-account-key</v-icon>
                  </v-btn>
                </template>
                <span>Administrar control de acceso</span></v-tooltip
              >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="grey"
                    @click="handleMovement(item)"
                  >
                    <v-icon>mdi-account-switch-outline</v-icon>
                  </v-btn>
                </template>
                <span>Realizar movimiento</span></v-tooltip
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center">Editar Usuario</h2>

        <span v-if="createdAt"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Nombre"
                v-model="name"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Apellido"
                v-model="lastname"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.email]"
                label="Email"
                v-model="email"
                type="email"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                v-mask="phoneMask"
                :rules="[rules.required, rules.maxNumber]"
                label="Teléfono"
                v-model="phone"
                type="text"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                label="Usuario"
                :rules="[rules.required, rules.max]"
                v-model="username"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                label="Contraseña"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                v-model="password"
                counter
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                label="Confirmar contraseña"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                v-model="confirmPassword"
                counter
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%">País</label>
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  placeholder="Seleccionar"
                  :custom-label="countryName"
                  v-model="country"
                  :options="countries"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%"
                  >Módulos</label
                >
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="name"
                  placeholder="Seleccionar"
                  v-model="selectedModules"
                  :options="modules"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <div
                class="row"
                style="display: flex; justify-content: center; align-items: center"
              >
                <img
                  v-if="item.avatar"
                  :src="item.avatar.route"
                  :alt="item.name"
                  style="width: 40%"
                />
                <v-file-input
                  v-model="image"
                  chips
                  :rules="[rules.fileSize]"
                  ref="file"
                  show-size
                  accept="image/png, image/jpeg, image/jpg"
                  :label="'Seleccione una foto'"
                  style="width: 60%"
                />
              </div>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox :label="active ? 'Activo' : 'Inactivo'" v-model="active" />
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            :disabled="!valid"
            dark
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            dark
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            dark
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
    <modal
      @closed="setDefaultModal()"
      name="rolesModal"
      :height="'80%'"
      :width="'80%'"
      :adaptive="true"
    >
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center">
          Asignación de Roles - {{ item.name + ' ' + item.lastname }}
        </h2>
        <v-stepper elevation="3" v-model="step" vertical>
          <v-stepper-step :complete="step > 1" step="1">
            Seleccione un módulo
          </v-stepper-step>

          <v-stepper-content align="center" step="1">
            <v-row class="mx-0 my-4" style="width: 80%">
              <label style="margin: 0px auto; width: 40%"
                >Módulos asignados: <br /><br /><!--<span
                  v-if="assingedModule !== ''"
                  class="d-flex mb-2"
                  style="font-size: 13px; justify-content: center"
                  ><strong style="display: contents">{{ assingedModule.name }}</strong>
                  <br />
                  Roles asignados: 0 <br />
                  Roles disponibles: 3</span
                >--></label
              >
              <multiselect
                style="width: 60%; min-height: 80px"
                track-by="id"
                label="name"
                placeholder="Seleccionar"
                :custom-label="moduleLabel"
                v-model="assingedModule"
                :options="item.module ? item.module : []"
                @input="getDiffRoles()"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
            /></v-row>
            <v-row />

            <v-btn color="primary" :disabled="assingedModule === ''" @click="step = 2">
              Continuar
            </v-btn>
            <v-btn text @click.prevent="$modal.hide('rolesModal')"> Cerrar </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 2" step="2">
            Asigne los roles necesarios para el modulo
            <strong style="display: contents">{{ assingedModule.name }}</strong>
          </v-stepper-step>

          <v-stepper-content align="center" step="2">
            <div
              class="row mx-2 mb-4"
              style="justify-content: center; width: 90%"
              align="center"
            >
              <div class="col-4">
                <v-card>
                  <h3>Roles disponibles</h3>
                  <v-divider />
                  <draggable
                    class="list-group"
                    :list="list1"
                    :group="'a'"
                    tag="ul"
                    :sort="false"
                    @change="
                      ''

                    "
                  >
                    <div
                      class="list-group-item"
                      v-for="(element, index) in list1"
                      :key="index"
                    >
                      {{ element }}
                    </div>
                  </draggable>
                </v-card>
              </div>

              <div class="col-4">
                <v-card>
                  <h3>Roles asignados</h3>
                  <v-divider />
                  <draggable
                    class="list-group"
                    :list="list2"
                    :group="'a'"
                    :sort="false"
                    @change="
                      ''

                    "
                  >
                    <div
                      class="list-group-item"
                      v-for="(element, index) in list2"
                      :key="index"
                    >
                      {{ element }}
                    </div>
                  </draggable>
                </v-card>
              </div>
            </div>
            <v-btn color="primary" @click="assignRoles()"> Guardar </v-btn>
            <v-btn text @click.prevent="step = 1"> Regresar </v-btn>
            <v-btn text @click.prevent="$modal.hide('rolesModal')"> Cancelar </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </modal>
    <modal name="movementModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-2">Crear Movimiento de {{ item | toUserText }}</h2>

        <v-form
          ref="formMovement"
          v-model="movement.valid"
          style="width: 100%"
          class="row"
        >
          <v-col cols="12" lg="4" md="6">
            <v-row style="margin: 0px">
              <label style="margin: 0px auto; font-size: 13px; width: 20%">Tipo</label>
              <multiselect
                style="width: 80%"
                track-by="id"
                label="name"
                :disabled="flow === 'edit' ? true : false"
                placeholder="Seleccionar"
                v-model="movement.type"
                :options="movement.movementTypes"
                @input="getMovementReasons()"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-row style="margin: 0px">
              <label style="margin: 0px auto; font-size: 13px; width: 20%">Cargo</label>
              <multiselect
                style="width: 80%"
                track-by="id"
                label="name"
                :disabled="flow === 'edit' ? true : false"
                placeholder="Seleccionar"
                v-model="movement.position"
                :options="movement.positions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-row style="margin: 0px">
              <label style="margin: 0px auto; font-size: 13px; width: 20%">Motivo</label>
              <multiselect
                style="width: 80%"
                track-by="id"
                label="description"
                :disabled="flow === 'edit' ? true : false"
                placeholder="Seleccionar"
                v-model="movement.movementReason"
                :options="movement.movementReasons"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
              />
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              counter
              :rules="[rules.required, rules.max]"
              label="Observación"
              v-model="movement.observation"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-text-field
              show-current
              :rules="[rules.required, rules.max]"
              label="Fecha efectiva"
              v-model="movement.date"
              type="date"
            />
          </v-col>
        </v-form>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            :disabled="!movement.valid"
            dark
            color="primary"
            @click="createMovement()"
          >
            Crear Movimiento
          </v-btn>

          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            dark
            @click="resetMovement"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            dark
            @click="$modal.hide('movementModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import draggable from 'vuedraggable'
  import { decryptData, encryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    components: {
      draggable,
    },
    props: {
      position: Object,
    },
    data() {
      return {
        config: {},
        headers: [
          {
            text: 'Avatar',
            align: 'center',
            sortable: false,
            value: 'avatar',
            width: '100px',
          },
          {
            text: 'Nombre',
            align: 'left',
            value: 'fullName',
          },
          // { text: 'Última conexión', value: 'deadline' },
          // { text: 'Elearning', value: 'progress' },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false,
            align: 'center',
            width: '140px',
          },
        ],
        loading: false,
        flow: '',
        page: 1,
        pages: 1,
        totalItems: 0,
        itemsPerPage: 10,
        loadingText: 'Cargando procesos',
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        item: { id: null },
        list1: [],
        list2: [],
        step: 1,
        movement: {
          valid: true,
          position: {},
          positions: [],
          movementReasons: [],
          movementReason: {},
          movementTypes: [
            { id: 2, name: 'Transferencia' },
            { id: 3, name: 'Ascenso' },
            { id: 4, name: 'Egreso' },
          ],
          type: { id: 2, name: 'Transferencia' },
          observation: '',
          date: new Date().toISOString().split('T')[0],
        },
        modules: [],
        selectedModules: [],
        showPassword: false,
        active: false,
        phone: '',
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        lastname: '',
        email: '',
        countries: [],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        country: '',
        assingedModule: '',
        selectCountryData: [],
        image: null,
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 6 : true) || '6 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
      }
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      users() {
        if (this.position && this.position.users) {
          const users = this.position.users
          users.forEach((user) => {
            user.fullName = `${user.name} ${user.lastname} | ${user.email}`
          })
          return users
        } else {
          return []
        }
      },
    },
    filters: {
      toUserText(user) {
        if (!user) return ''
        return `${user.name} ${user.lastname} | ${user.email}`
      },
    },
    watch: {
      position() {},
      company() {},
    },
    created() {
      this.getModules()
      this.getCountries()
      this.getPositions()
      this.getMovementReasons()
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      handleMovement(user) {
        this.item = user
        this.$modal.show('movementModal')
      },

      moduleLabel({ name }) {
        return name
      },

      handleRolesRedirect() {
        if (this.item.module && this.item.module.length > 0) {
          this.$modal.show('rolesModal')
        } else {
          this.$swal({
            title: '¡Aún no!',
            text:
              'Para asignar roles y permisos, primero debes asignar los modulos disponibles para el usuario seleccionado.',
            icon: 'info',
          })
        }
      },

      async getUser(user, flow) {
        this.loading = true
        if (this.item.id !== user.id) {
          const withString = '&with[]=module&with[]=countries'
          this.$axios.get('users/' + user.id + withString).then((response) => {
            this.item = response.data
            if (flow === 'edit') {
              this.handleEdit()
            } else {
              this.handleRolesRedirect()
            }
            this.loading = false
          })
        } else {
          if (flow === 'edit') {
            this.handleEdit()
          } else {
            this.handleRolesRedirect()
          }
          this.loading = false
        }
      },

      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },

      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },

      setDefaultModal() {
        this.assingedModule = ''
        this.step = 1
        this.lis1 = []
        this.list2 = []
      },

      async getModules() {
        this.$axios
          .get('modules?limit=500&company_id=' + this.company.id)
          .then((response) => {
            this.modules = this.modules.concat(response.data)
          })
      },

      async getCountries() {
        this.$axios.get('countries?limit=500' + this.country).then((response) => {
          this.countries = this.countries.concat(response.data)
        })
      },

      async getPositions() {
        this.$axios
          .get('positions?limit=1000&type_id=' + this.company.type_id)
          .then((response) => {
            this.movement.positions = response.data
          })
      },

      async getMovementReasons() {
        if (this.movement.type && this.movement.type.id) {
          this.$axios
            .get('movement-reasons?limit=1000&type=' + this.movement.type.id)
            .then((response) => {
              this.movement.movementReasons = response.data
            })
        }
      },

      async getDiffRoles() {
        this.$axios
          .post('diff-roles', {
            user_id: this.item.id,
            module_id: this.assingedModule.id,
          })
          .then((response) => {
            if (typeof response.data.diff === 'object') {
              this.list1 = Object.values(response.data.diff)
            } else {
              this.list1 = response.data.diff
            }
            if (typeof response.data.diff === 'object') {
              this.list2 = Object.values(response.data.intersect)
            } else {
              this.list2 = response.data.intersect
            }
          })
      },

      async handleEdit() {
        this.name = this.item.name
        this.lastname = this.item.lastname
        this.country =
          this.item.countries && this.item.countries.length > 0
            ? this.item.countries[0]
            : ''
        this.email = this.item.email
        this.phone = this.item.phone
        this.username = this.item.username
        this.selectedModules = this.item.module ? this.item.module : []
        this.active = this.item.active
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      async createMovement() {
        const formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        formData.append('observation', this.movement.observation)
        if (this.item && this.item.id) {
          formData.append('user_id', this.item.id)
        }
        if (this.movement.position && this.movement.position.id) {
          formData.append('position_id', this.movement.position.id)
        }
        if (this.movement.movementReason && this.movement.movementReason.id) {
          formData.append('movement_reason_id', this.movement.movementReason.id)
        }
        if (this.movement.type && this.movement.type.id) {
          formData.append('type', this.movement.type.id)
        }
        if (this.movement.date) {
          formData.append('date', this.movement.date)
        }
        if (
          this.movement.image &&
          this.movement.image !== '' &&
          this.movement.image !== undefined
        ) {
          formData.append('file', this.movement.image)
        }
        formData.append('fileType', 'image')
        this.$axios.post('movements', formData).then(() => {
          this.$emit('reloadPosition')
          this.$modal.hide('movementModal')
        })
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      resetMovement() {
        this.$refs.formMovement.reset()
      },

      async saveItem() {
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }

        const formData = new FormData()
        formData.append('company_id', this.company.id)
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('email', this.email)
        formData.append('phone', this.phone)
        formData.append('type', 0)
        formData.append('username', this.username)
        if (this.country && this.country.id) {
          formData.append('countries[]', this.country.id)
        }
        formData.append('password', this.password)
        formData.append('password_confirmation', this.confirmPassword)
        formData.append('active', this.active ? 1 : 0)
        this.selectedModules.forEach((module) => {
          formData.append('modules[]', module.id)
        })
        if (this.image && this.image !== '' && this.image !== undefined) {
          formData.append('file', this.image)
        }
        formData.append('fileType', 'avatar')
        if (this.item.avatar && this.item.avatar.id) {
          formData.append('fileId', this.item.avatar.id)
        }
        formData.append('_method', 'patch')
        formData.append('fileResize[width]', 200)
        formData.append('fileResize[height]', 200)

        this.$axios
          .post('users/' + this.item.id, formData, this.config)
          .then((response) => {
            this.$modal.hide('crudModal')
            if (
              this.item.id === decryptData(this.$session.get('userId')) &&
              response.data.user.modules
            ) {
              this.$store.commit(
                'updateUserModules',
                encryptData(response.data.user.modules)
              )
            }
            this.$emit('reloadPosition')
          })
      },

      async assignRoles() {
        this.$axios
          .patch('users/' + this.item.id, {
            role: this.list2,
          })
          .then((response) => {
            if (this.item.id === decryptData(this.$session.get('userId'))) {
              this.$store.commit('updateUserRoles', encryptData(response.data.user.roles))
            }
            this.$modal.hide('rolesModal')
            this.setDefaultModal()
            this.$emit('reloadPosition')
          })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
