<template>
  <v-card class="neu-glow">
    <v-row class="mx-0 pa-2" align="center">
      <!-- title and subtitle -->
      <div class="text-h6">{{ title }}</div>
      <!-- <help :helpId="helpId" style="'margin: auto 10px'" /> -->
      <!-- Stats -->
      <!-- <div class="d-flex flex-column align-end">
        <v-btn icon class="neu-glow">
          <v-icon color="secondary">add</v-icon>
        </v-btn>
      </div> -->
    </v-row>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="movements"
        :items-per-page="5"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        class="neu-glow-inset"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td v-if="item.user && item.user.name">
              {{ item.user.name + ' ' + item.user.lastname }}
            </td>
            <td>{{ item.date }}</td>
            <td class="d-flex" style="justify-content: center; align-items: center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="grey"
                    @click="handleSelected(item)"
                  >
                    <v-icon>mdi-magnify-scan</v-icon>
                  </v-btn>
                </template>
                <span>Ver movimiento</span></v-tooltip
              >
            </td>
          </tr>
        </template>
        <template v-slot:no-data
          ><div class="text-center" />
          No hay movimientos realizados
        </template>
        <template v-slot:no-results
          ><div class="text-center" />
          No hay resultados para su búsqueda
        </template>
      </v-data-table>
    </v-card-text>
    <modal :name="'modal' + type.toString()" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center">{{ type | toTypeText }} de {{ position.name }}</h2>
        <v-row v-if="Object.keys(movement).length !== 0">
          <v-col cols="12" md="6">
            <h4>Usuario:</h4>
            {{ movement.user.name + ' ' + movement.user.lastname }}
          </v-col>
          <v-col cols="12" md="6">
            <h4>Tipo de movimiento:</h4>
            {{ movement.type | toTypeText }}
          </v-col>
          <v-col v-if="movement.movement_reason" cols="12" md="6">
            <h4>Tipo de movimiento:</h4>
            {{ movement.movement_reason.description }}
          </v-col>
          <v-col cols="12" md="6">
            <h4>Observación:</h4>
            {{ movement.observation }}
          </v-col>
        </v-row>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            dark
            @click="$modal.hide('modal' + type.toString())"
          >
            Cerrar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'

  export default {
    props: {
      title: String,
      position: Object,
      type: Number,
      // helpId: { type: Number, required: false },
    },
    data() {
      return {
        headers: [
          {
            text: 'Nombre',
            align: 'left',
            value: 'user.name',
          },
          {
            text: 'Fecha',
            align: 'left',
            value: 'date',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false,
            align: 'center',
            width: '80px',
          },
        ],
        movement: {},
      }
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      movements() {
        if (this.position && this.position.movements) {
          return this.position.movements.filter((movement) => movement.type === this.type)
        } else {
          return []
        }
      },
    },
    watch: {
      position() {},
      company() {},
    },
    filters: {
      toTypeText(type) {
        if (!type) return ''
        switch (type) {
          case 1:
            return 'Ingreso'
          case 2:
            return 'Transferencia'
          case 3:
            return 'Ascenso'
          case 4:
            return 'Egreso'

          default:
            return ''
        }
      },
    },
    methods: {
      handleSelected(movement) {
        this.movement = movement
        this.$modal.show('modal' + this.type.toString())
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
